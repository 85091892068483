<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-row>
            <b-col cols="12" xl="8" md="8">
                <b-card no-body>
                    <b-card-body>
                        <b-row>
                            <b-col cols="12" md="12">
                                <search-invoices @selected="selectInvoice" @resetForm="resetForm" :showArticles="true"
                                    :showDetail="true" @onSearch="onSearchQuery" :doctoPvId="doctoPvId"
                                     />
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>

            <!--  -->
            <b-col cols="12" md="4" xl="4" class="invoice-actions">
                <b-row>
                    <b-col cols="12">                        
                        <info-routing-invoice
                            :info="invoice" :is-all="invoice.delivery_rounting || isSearch ? false : true"
                            @selectRouting="selectRouting" :is-output="isOutput"/>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-overlay>
</template>

<script>
import router from "@/router";
import store from "@/store";
import BCardCode from "@core/components/b-card-code";
import {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BTableSimple, BThead, BTr, BTh, BTd, BTbody, BTfoot,
    BCardText,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BAvatar,
    BFormTextarea,
    BButton,
    BFormInvalidFeedback,
    VBToggle,
    BOverlay,
    BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import EventBus from "@/event-bus"
import SearchInvoices from '@/@core/components/invoices/SearchInvoices.vue'
import InfoRoutingInvoice from "@/@core/components/invoices/InfoRoutingInvoice.vue";

export default {
    name: 'RoutingOutputs',
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BCardFooter,
        BTableSimple, BThead, BTr, BTh, BTd, BTbody, BTfoot,
        BCardText,
        BButton,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormCheckbox,
        BFormTextarea,
        BAvatar,
        vSelect,
        flatPickr,
        ValidationProvider,
        BFormInvalidFeedback,
        ValidationObserver,
        BOverlay,
        BSpinner,
        BCardCode,

        //
        SearchInvoices,
        InfoRoutingInvoice,
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    data () {
        return {
            loading: false,
            activeSearchCustomer: false,
            resetSearch: false,
            invoice: {},
            isOnPreRoute: false,
            isSearch: false,
            doctoPvId: null,
        }
    },
    computed: {
        isOutput() {
            return this.$route.name === 'apps-routings-outputs'
        }
    },
    created() {        
    },
    async mounted() {        
        
    },
    methods: {
        selectRouting(evt) {     
            EventBus.$emit('selectedDocto', evt)
        },
        onSearchQuery(evt) {
            this.isSearch = evt ? true : false
        },
        selectInvoice(e) {
            this.invoice = { ...e }
            console.warn(this.invoice)
        },
        resetForm() {
            this.invoice = {}
            this.isOnPreRoute = false
            this.isSearch = false
        },
    },
    watch: {

    }
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/vue/libs/vue-flatpicker.scss";

.card-footer {
    padding: 0.75rem 0.75rem;
    background-color: rgba(34, 41, 47, 0.03);
    border-top: 1px solid rgba(34, 41, 47, 0.125);
}
</style>